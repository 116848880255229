<!--
File: WorkCategoryEditForm.vue
Description: form for adding/editing a single Work Category.
-->
<template>
  <form>
    <md-card>
      <md-card-header class="md-card-header-icon">
        <div class="card-icon"> <md-icon>perm_identity</md-icon> </div>
        <h4 class="title"> {{ $t(`route.work_category_${oper}`) }} </h4>
      </md-card-header>
      <md-card-content>
        <div class="md-layout-item md-small-size-100 md-size-50">
          <md-field :class="[
            { 'md-valid': !errors.has('description') },
            { 'md-error': errors.has('description') }
          ]">
            <label>{{ $t('stdCols.description') + ' (RU)' }}</label>
            <md-input v-model="description" type="text" data-vv-name="description" required
              v-validate="modelValidations.description"></md-input>
          </md-field>

          <md-field :class="[
            { 'md-valid': !errors.has('description_en') },
            { 'md-error': errors.has('description_en') }
          ]">
            <label>{{ $t('stdCols.description') + ' (EN)' }}</label>
            <md-input v-model="description_en" type="text" data-vv-name="description_en" required
              v-validate="modelValidations.description_en"></md-input>
          </md-field>
        </div>

        <div class="md-layout md-size-50">
          <div class="md-layout-item md-small-size-100 md-size-50">
          </div>
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-success" native-type="submit" @click.native.prevent="validate"
              :disabled="saveBtnDisabled">
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onCancel">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import Swal from 'sweetalert2'

  export default {
    name: 'deu-edit-form',
    data() {
      return {
        work_category_id: null,
        description: null,
        description_en: null,

        modelValidations: {
          description: { required: true },
          description_en: { required: true },
        }
      }
    },
    props: {
      oper: String
    },
    created() {
      const { work_category_id = null } = this.$route.params
      this.work_category_id = work_category_id
      if (this.oper === 'upd' && work_category_id) {
        this.$store.dispatch('LOAD_WORK_CATEGORY_BY_ID', work_category_id).then((res) => {
          const theWorkCategory = Array.isArray(res) ? res[0] : res
          this.description = theWorkCategory.description
          this.description_en = theWorkCategory.description_en
        })
      }
    },

    methods: {
      onCancel() {
        this.$router.push('/reference_data/work_categories')
      },
      async validate() {
        this.$validator.validateAll().then((isValid) => {
          if (isValid) {
            const theWorkCategory = {
              description: this.description,
              description_en: this.description_en,
            }
            const alert = {
              type: 'success',
              text: this.$t(`budget.category`) + this.$t(`label.was_saved`, {
                name_ru: this.description,
                name_en: this.description_en
              }),
              footer: ''
            }

            const reqData = this.oper === 'add' ? theWorkCategory : { id: this.work_category_id, work_category: theWorkCategory }
            this.$store.dispatch(`${this.oper.toUpperCase()}_WORK_CATEGORY`, reqData).then(() => {
              Swal.fire(alert).then(() => {
                this.$nextTick(() => this.$validator.reset())
              })
            }, (err) => {
              alert.type = 'error'
              alert.text = this.$t(`budget.category`) + this.$t(`label.was_not_saved`, {
                name_ru: this.description,
                name_en: this.description_en
              })
              alert.footer = err
              Swal.fire(alert)
            })
          }
        })
        // To return back to the list
        this.$router.push('/reference_data/work_categories')
      }
    },
    watch: {
    },
    computed: {
      saveBtnDisabled() {
        return !this.description || this.description == '' || !this.description_en || this.description_en == ''
      }
    }
  }
</script>
<style>
.md-button+.md-button {
  margin-left: 10px;
}
</style>
    